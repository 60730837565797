
Vue.component('itemViewListComponent',{
    props:['item','stock'],
    data:()=>{
        return {
            itemInCar:0,
            Qty:1,
            favorite: false,
        }
    },
    watch: {
        item: function () {
            this.favorite = this.$store.getters.getFavorites.indexOf(this.item.Code)!=-1
            this.$store.dispatch('updateCarAmount');
        },
        currentItemPrice:function(){
            this.$store.dispatch('updateCarAmount');
        }
    },
    computed:{
        currentItemPrice(){
            return this.$store.getters.showItemPrice(this.item);
        },
        showStock(){
            console.log('Stock Option', this.stock);
            if(this.stock)
                return this.stock;
            else
                return this.$store.getters.getShowStock;
        }
    },
    methods: {
        openItemView:function(){
            if(this.item)
                this.$router.push(`/view/${this.item.Code}`);
        },
        ItemComponentAddToCarOld:function(){
            if(isNaN(this.Qty) || this.Qty==0) {
                this.Qty = 0;
                return;
            }
            if(this.item.carCant)
                this.item.carCant=parseInt(this.item.carCant) +parseInt(this.Qty);
            else
                this.item.carCant=parseInt(this.Qty);
            this.$store.dispatch('ItemComponentAddToCar',this.item);
            this.$store.dispatch('updateCarAmount');
        },
        ItemComponentAddToCar:function(){
            if(isNaN(this.Qty) || this.Qty==0) {
                this.Qty = 0;
                return;
            }
            this.$store.dispatch('ItemComponentAddToCar',{id:this.item.Code,cant:this.Qty});
            this.$store.dispatch('updateCarAmount');
        },
        itemQtyIncrement:function(){
            if(Number.isInteger(this.Qty))
                this.Qty += 1;
            else
                this.Qty=1;

        },
        itemQtyDecrement:function(){
            if(this.Qty>1)
                this.Qty -= 1;
            else
                this.Qty=0;
        },
        toggleFavorite(){
            this.$store.dispatch('toggleFavourite', this.item.Code);
            Vue.nextTick(() => {
                this.favorite = !this.favorite;
            })
            console.log('Item',this.favorite);
        }
    },
    mounted:function(){
        this.favorite = this.$store.getters.getFavorites.indexOf(this.item.Code)!=-1;
    },
    template:`<div class="card list-view">
                     <div class="container-item">
                        <table width="600" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr @click="openItemView(item)">
                                <td width="150" rowspan="2" class="center-align">
                                    <router-link :to="'/view/'+encodeToURL(item.Code)">
                                        <itemSliderComponent :key="'slide-list-'+item.Code" :item="item" :stockFlag="false" :container="false"  :zoom="false" v-if="$store.getters.getSetting && $store.getters.getSetting.DemoPhotos"></itemSliderComponent>
                                    </router-link>
                                </td>
                                <td colspan="8" >
                                    <span class="tit-producto">{{item.Name}}</span>
                                    <span v-if="item.SpecialPrice" class="right-align" style="float:right"><img src="../static/ecommerce/assets/img/especial.png" width="35"></span>
		                            <span v-if="item.Ecofriendly" class="right-align" style="float:right"><img src="../static/ecommerce/assets/img/eco.png" width="35" alt=""> </span>
                                </td>
                            </tr>
                            <tr>
                              <td width="160" class="desc-producto card-producto"><router-link :to="'/view/'+item.Code"> Cod. {{item.Code}} </router-link></td>
                              <td width="160" class="desc-producto card-producto"><router-link :to="'/view/'+item.Code">Empaque {{item.Unit}}</router-link></td>
                              <td width="100" class="desc-producto card-producto"><router-link :to="'/view/'+item.Code">Art. {{item.AlternativeCode}}</router-link></td>
                              <td width="150" class="card-producto center-align" v-if="showStock"><router-link :to="'/view/'+item.Code"><strong>Stock {{item.Qty}}</strong></router-link></td> 
                              <td width="150" class="card-producto center-align"><router-link :to="'/view/'+item.Code"><strong class="pricered">$ {{currentItemPrice}}</strong></router-link></td>
                              <td width="180" class="card-producto">
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                      <tr>
                                        <td width="20" class="center-align" @click="itemQtyIncrement" ><i class="fas fa-angle-up waves-effect" ></i></td>
                                        <td class="center-align">
                                            <input :ref="'carCant-'+item.Code" v-model="Qty" class="inputch" type="text" min="0" max="50" />
                                        </td>
                                        <td width="20" class="center-align" @click="itemQtyDecrement" ><i class="fas fa-angle-down waves-effect" ></i></td>
                                      </tr>
                                    </tbody>
                                  </table>
                              </td>
                              <td width="37" class="card-producto center-align" @click.stop="ItemComponentAddToCar(item)"><i class="fas fa-cart-plus" style="color:#40a626"  ></i></td>
                              <td width="37" class="card-producto center-align">
                                <span @click="toggleFavorite()" >
                                    <template v-if="favorite">
                                        <icon name="heart"  color="red" :title="tr('Remove From Favorite')"></icon>
                                    </template>
                                    <template v-else>
                                        <icon name="regular/heart" color="green" :title="tr('Add To Favorite')"></icon>
                                    </template>                      
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                     </div>
                  </div>`
})
